import { IJwtToken } from 'types';

export const parseJwt = <T = IJwtToken>(token: string): T => {
  if (!token) return {} as T;

  const tokens = token.split('.');
  const base64Url = tokens.length > 1 ? tokens[1] : null;
  if (!base64Url) return {} as T;

  try {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );
    return JSON.parse(jsonPayload) as T;
  } catch (e) {
    return {} as T;
  }
};
