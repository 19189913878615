import { CustomTheme } from 'types';

function MuiFormHelperText(theme: CustomTheme) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
  };
}
export default MuiFormHelperText;
