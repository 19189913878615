import { IBillingEntityForm, ISubscriptionPlan } from './Billing';
import { ICurrency } from './price';

export interface IClient {
  id: string;
  user: IUserClient;
  details: IClientDetails;
  billing: IBillingEntityForm;
  billingPlan?: ISubscriptionPlan;
  confirmed?: boolean;
  created_at: string;
  updated_at: string;
  totalConnectors?: number;
  extra: IClientExtra;
  level: number;
  parentId?: string | null;
  parentBilling?: IBillingEntityForm;
}

export interface IClientExtra {
  connectors: IClientTotalConnectors;
  stations: IClientTotalConnectors;
}
export interface IClientTotalConnectors {
  totalAC: number;
  totalDC: number;
}

export enum IClientType {
  company = 'company',
  individual = 'individual',
}

export interface IClientDetails {
  type: IClientType;
  personalAddress: string;
  companyName?: string;
  companyId?: string;
  companyAddress?: string;
  companyNumber?: string;
}

export interface IUserClient {
  email: string;
  firstName: string;
  lastName: string;
  details: IUserDetails;
  role?: string;
}

export interface IUserDetails {
  phone: string;
}

export interface IClientSelected {
  id: string;
  label: string;
  level?: number;
  parentId?: string;
  currency?: ICurrency;
  vat?: number;
}

export interface IEntityWithClient {
  clientId?: string;
  clientName?: string;
  partnerId?: string;
  partnerName?: string;
}

export interface IChooseClientRef {
  getInputs: () => {
    selectedPartner: IClientSelected;
  };
  setPartnerFromObject: (entityWithClient: IEntityWithClient, ignoreSelf: boolean) => void;
  setPartner: (client: IClientSelected) => void;
}
