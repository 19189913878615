import { IBillingEntity, IBillingEntityForm } from 'types';

export const adaptBillingToApi = (billing?: IBillingEntityForm | IBillingEntity) => {
  if (!billing) {
    return {};
  }
  if (billing.inherited) {
    return {
      inherited: true,
    };
  }

  return billing;
};
