import { useState, useRef, useEffect } from 'react';
import { addSeconds, startOfDay } from 'date-fns';
import { Box, TextField, Slider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField } from '@mui/x-date-pickers/TimeField';

interface IProps {
  column: any;
  minValue: number;
  maxValue: number;
  step: number;
  typeTime?: boolean;
}

export function CustomRangeSlider({ column, minValue, maxValue, step, typeTime = false }: IProps) {
  const inputRefMinValue = useRef<any>(null);
  const inputRefMaxValue = useRef<any>(null);

  const [sliderMinValueInput, setSliderMinValueInput] = useState<string>(String(minValue));
  const [sliderMaxValueInput, setSliderMaxValueInput] = useState<string>(String(maxValue));
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const filterValue = column.getFilterValue();
    if (filterValue && Array.isArray(filterValue)) {
      setSliderMinValueInput(String(filterValue[0]));
      setSliderMaxValueInput(String(filterValue[1]));
    } else {
      setSliderMinValueInput(String(minValue));
      setSliderMaxValueInput(String(maxValue));
    }
    setLoading(false);
  }, [column.getFilterValue(), minValue, maxValue]);

  const timeFromSliderMinValueInput = addSeconds(startOfDay(new Date()), Number(sliderMinValueInput));
  const timeFromSliderMaxValueInput = addSeconds(startOfDay(new Date()), Number(sliderMaxValueInput));

  const handleSliderMinValueInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event?.target?.value.replace(',', '.');
    const numericValue = parseFloat(newValue);
    const stepDecimalPlaces = getStepDecimalPlaces(step);

    if (step === 1) {
      newValue = newValue.replace('.', '').replace(',', '');
    }

    if (!Number.isNaN(numericValue) && numericValue > maxValue) {
      newValue = String(maxValue);
    }
    const regexPattern = new RegExp(`^\\d*\\.?\\d{0,${stepDecimalPlaces}}$`);
    if (newValue === '' || regexPattern.test(newValue)) {
      setSliderMinValueInput(newValue);
    }
  };

  const handleSliderMaxValueInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event?.target?.value.replace(',', '.');
    const numericValue = parseFloat(newValue);
    const stepDecimalPlaces = getStepDecimalPlaces(step);

    if (step === 1) {
      newValue = newValue.replace('.', '').replace(',', '');
    }

    if (!Number.isNaN(numericValue) && numericValue > maxValue) {
      newValue = String(maxValue);
    }
    const regexPattern = new RegExp(`^\\d*\\.?\\d{0,${stepDecimalPlaces}}$`);
    if (newValue === '' || regexPattern.test(newValue)) {
      setSliderMaxValueInput(newValue);
    }
  };

  const getStepDecimalPlaces = (stepValue: number) => {
    if (stepValue === 1) return 0;
    if (stepValue === 0.1) return 1;
    if (stepValue === 0.01) return 2;
    return 0;
  };

  const handleSliderMinValueBlur = () => {
    const numericValue = parseFloat(sliderMinValueInput);
    const maxNumericValue = parseFloat(sliderMaxValueInput);
    if (!Number.isNaN(numericValue)) {
      if (numericValue > maxValue) {
        setSliderMinValueInput(String(maxValue));
        column.setFilterValue([maxValue, maxNumericValue]);
      } else if (numericValue > maxNumericValue) {
        setSliderMinValueInput(sliderMaxValueInput);
        setSliderMaxValueInput(String(numericValue));
        column.setFilterValue([maxNumericValue, numericValue]);
      } else {
        column.setFilterValue([numericValue, maxNumericValue]);
      }
    } else {
      column.setFilterValue([0, maxNumericValue]);
    }
  };

  const handleSliderMaxValueBlur = () => {
    const numericValue = parseFloat(sliderMaxValueInput);
    const minNumericValue = parseFloat(sliderMinValueInput);
    if (!Number.isNaN(numericValue)) {
      if (numericValue > maxValue) {
        setSliderMaxValueInput(String(maxValue));
        column.setFilterValue([minNumericValue, maxValue]);
      } else if (numericValue < minNumericValue) {
        setSliderMaxValueInput(sliderMinValueInput);
        setSliderMinValueInput(String(numericValue));
        column.setFilterValue([numericValue, minNumericValue]);
      } else {
        column.setFilterValue([minNumericValue, numericValue]);
      }
    } else {
      column.setFilterValue([minNumericValue, 0]);
    }
  };

  const handleSliderMinValueInputTime = (newValue: Date | null) => {
    if (newValue) {
      const newSeconds = newValue.getHours() * 3600 + newValue.getMinutes() * 60 + newValue.getSeconds();
      if (newSeconds <= maxValue) {
        setSliderMinValueInput(String(newSeconds));
      } else {
        setSliderMinValueInput(String(maxValue));
      }
    }
  };

  const handleSliderMaxValueInputTime = (newValue: Date | null) => {
    if (newValue) {
      const newSeconds = newValue.getHours() * 3600 + newValue.getMinutes() * 60 + newValue.getSeconds();
      if (newSeconds <= maxValue) {
        setSliderMaxValueInput(String(newSeconds));
      } else {
        setSliderMaxValueInput(String(maxValue));
      }
    }
  };

  const handleSliderMinValueBlurTime = () => {
    const numericValue = parseFloat(sliderMinValueInput);
    const maxNumericValue = parseFloat(sliderMaxValueInput);
    if (!Number.isNaN(numericValue)) {
      if (numericValue > maxValue) {
        setSliderMinValueInput(String(maxValue));
        column.setFilterValue([maxValue, maxNumericValue]);
      } else if (numericValue > maxNumericValue) {
        setSliderMinValueInput(sliderMaxValueInput);
        setSliderMaxValueInput(String(numericValue));
        column.setFilterValue([maxNumericValue, numericValue]);
      } else {
        column.setFilterValue([numericValue, maxNumericValue]);
      }
    } else {
      column.setFilterValue([0, maxNumericValue]);
    }
  };

  const handleSliderMaxValueBlurTime = () => {
    const numericValue = parseFloat(sliderMaxValueInput);
    const minNumericValue = parseFloat(sliderMinValueInput);
    if (!Number.isNaN(numericValue)) {
      if (numericValue > maxValue) {
        setSliderMaxValueInput(String(maxValue));
        column.setFilterValue([minNumericValue, maxValue]);
      } else if (numericValue < minNumericValue) {
        setSliderMaxValueInput(sliderMinValueInput);
        setSliderMinValueInput(String(numericValue));
        column.setFilterValue([numericValue, minNumericValue]);
      } else {
        column.setFilterValue([minNumericValue, numericValue]);
      }
    } else {
      column.setFilterValue([minNumericValue, 0]);
    }
  };

  const handleChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setSliderMinValueInput(String(newValue[0]));
      setSliderMaxValueInput(String(newValue[1]));
    }
  };

  const handleChangeCommitted = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      if (newValue[0] === minValue && newValue[1] === maxValue) {
        column.setFilterValue(undefined);
      } else {
        column.setFilterValue(newValue);
      }
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        px: 2,
        mb: 0.5,
      }}
    >
      {!loading && (
        <>
          {typeTime ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '25px',
              }}
            >
              {Number(sliderMinValueInput) === maxValue ? (
                <Box sx={{ mr: -2, fontSize: '12px', fontWeight: 400, alignItems: 'center' }}>&gt;24h</Box>
              ) : (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimeField
                    inputRef={inputRefMinValue}
                    ampm={false}
                    hiddenLabel
                    format="HH:mm:ss"
                    sx={{ ml: -2 }}
                    inputProps={{
                      style: {
                        fontSize: '12px',
                        width: '56px',
                        padding: '4px 2px 2px 2px',
                        textAlign: 'center',
                      },
                    }}
                    value={timeFromSliderMinValueInput}
                    onChange={handleSliderMinValueInputTime}
                    onBlur={handleSliderMinValueBlurTime}
                  />
                </LocalizationProvider>
              )}
              {Number(sliderMaxValueInput) === maxValue ? (
                <Box sx={{ mr: -2, fontSize: '12px', fontWeight: 400 }}>&gt;24h</Box>
              ) : (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimeField
                    inputRef={inputRefMaxValue}
                    ampm={false}
                    hiddenLabel
                    format="HH:mm:ss"
                    sx={{ mr: -2 }}
                    inputProps={{
                      style: {
                        fontSize: '12px',
                        width: '56px',
                        padding: '4px 2px 2px 2px',
                        textAlign: 'center',
                      },
                    }}
                    value={timeFromSliderMaxValueInput}
                    onChange={handleSliderMaxValueInputTime}
                    onBlur={handleSliderMaxValueBlurTime}
                  />
                </LocalizationProvider>
              )}
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '25px' }}>
              <TextField
                inputRef={inputRefMinValue}
                hiddenLabel
                type="text"
                sx={{
                  ml: -2,
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                  '& input::placeholder': {
                    color: 'inherit',
                    opacity: 1,
                  },
                }}
                inputProps={{
                  inputMode: 'decimal',
                  style: {
                    fontSize: '12px',
                    width: '60px',
                    padding: '4px 2px 2px 6px',
                  },
                }}
                placeholder="0"
                value={sliderMinValueInput}
                onChange={handleSliderMinValueInput}
                onBlur={handleSliderMinValueBlur}
              />
              <TextField
                inputRef={inputRefMaxValue}
                hiddenLabel
                type="text"
                sx={{
                  mr: -2,
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                  '& input::placeholder': {
                    color: 'inherit',
                    opacity: 1,
                  },
                }}
                inputProps={{
                  inputMode: 'decimal',
                  style: {
                    fontSize: '12px',
                    width: '60px',
                    padding: '4px 2px 2px 6px',
                  },
                }}
                placeholder="0"
                value={sliderMaxValueInput}
                onChange={handleSliderMaxValueInput}
                onBlur={handleSliderMaxValueBlur}
              />
            </Box>
          )}
          <Slider
            value={[Number(sliderMinValueInput) || 0, Number(sliderMaxValueInput) || 0]}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            step={step}
            min={minValue}
            max={maxValue}
          />
        </>
      )}
    </Box>
  );
}
