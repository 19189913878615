import { Box, InputLabel, SxProps, Typography } from '@mui/material';
import * as React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'themes/defaultTheme';
import { InfoTooltip } from 'components/common/InfoTooltip';
import { InputTextRequiredOrOptional } from 'components/common/input/InputTextRequiredOrOptional';
import { getInputBoxStyle, getInputValueStyle, getLabelStyle } from 'components/utils';
import { defaultStyling } from 'types/styling';
import { IInputText } from 'types';
import { ChangeEvent } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { ItemLabelValue } from '../ItemLabelValue';
import { BasicInput } from './BasicInput';

export function CustomInputText({
  id,
  label,
  placeholder = '',
  value,
  setValue,
  validation,
  valueDirty,
  setValueDirty,
  sx = {},
  type = 'text',
  disabled = false,
  showDisabledWithoutOpacity = false,
  inputProps,
  extraError = false,
  setExtraError,
  extraErrorMessage,
  isSuccess = false,
  containerSx,
  infoTooltip,
  showCloseButton = false,
  onEnterPress = () => null,
  labelSize = 'lg',
  validText = '',
  styling = { ...defaultStyling },
  showRequired = true,
  warningMessage,
  showValidationErrorIcon = false,
  optional,
  ...data
}: IInputText) {
  const onClearInput = () => {
    setExtraError && setExtraError(false);
    setValue({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
  };

  if (disabled && showDisabledWithoutOpacity) {
    return <ItemLabelValue label={label} value={value} size={labelSize} styling={styling} />;
  }

  const { isFlexRow = false } = styling;

  const containerStyle = {
    ...getInputBoxStyle(isFlexRow),
    ...(containerSx ?? ({} as SxProps)),
  };

  return (
    <Box sx={containerStyle}>
      {!!label?.length && (
        <InputLabel sx={getLabelStyle(isFlexRow, labelSize, styling?.labelCustomStyle ?? {})}>
          {label}
          {!disabled && showRequired && <InputTextRequiredOrOptional required={optional ? false : validation} />}
          {infoTooltip && <InfoTooltip tooltipComponent={infoTooltip} />}
        </InputLabel>
      )}

      <Box
        sx={{
          ...getInputValueStyle(isFlexRow),
          position: 'relative',
        }}
      >
        <BasicInput
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          value={value ?? ''}
          setValue={setValue}
          valueDirty={valueDirty}
          setValueDirty={setValueDirty}
          validation={validation}
          extraError={extraError}
          setExtraError={setExtraError}
          extraErrorMessage={extraErrorMessage}
          type={type}
          onEnterPress={onEnterPress}
          sx={sx}
          inputProps={inputProps}
          warningMessage={warningMessage}
          {...data}
        />

        {value?.length > 0 && showCloseButton && (
          <Box
            onClick={() => onClearInput()}
            sx={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              fontSize: theme.components.MuiOutlinedInput.styleOverrides?.input?.fontSize,
              cursor: 'pointer',
              position: 'absolute',
              right: 8,
              top: 2,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CloseIcon />
          </Box>
        )}

        {isSuccess && (
          <Box
            sx={{ position: 'absolute', right: 8, top: 16, display: 'flex', alignItems: 'center', background: 'white' }}
          >
            <CheckCircleIcon sx={{ fontSize: 20, color: '#66bb6a' }} />
          </Box>
        )}

        {validation?.isValid && !!validText?.length && (
          <Box sx={{ mt: -1 }}>
            <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
              {validText}
            </Typography>
          </Box>
        )}

        {!!warningMessage ||
          (showValidationErrorIcon && !!validation && !validation?.isValid && valueDirty && (
            <Box
              sx={{
                position: 'absolute',
                right: 8,
                top: 16,
                display: 'flex',
                alignItems: 'center',
                background: 'white',
              }}
            >
              <CancelIcon sx={{ fontSize: 20, color: 'warning.dark' }} />
            </Box>
          ))}
      </Box>
    </Box>
  );
}
