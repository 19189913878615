import { TextField } from '@mui/material';
import * as React from 'react';
import { ChangeEvent, useRef } from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { IInputText } from 'types';

type IProps = Omit<
  IInputText,
  'label' | 'isSuccess' | 'infoTooltip' | 'showCloseButton' | 'showRequired' | 'labelSize' | 'ref'
>;
export const BasicInput = ({
  placeholder,
  value,
  setValue,
  valueDirty,
  setValueDirty,
  validation,
  extraError,
  extraErrorMessage,
  setExtraError,
  type = 'text',
  onEnterPress,
  disabled,
  sx,
  inputProps,
  warningMessage,
  extraProps,
  helperText,
}: IProps) => {
  const input = useRef();

  const localExtraProps: TextFieldProps = {
    ...extraProps,
  };

  if (inputProps) {
    localExtraProps.inputProps = { ...localExtraProps.inputProps, ...inputProps };
  }

  if (setValueDirty) {
    localExtraProps.onBlur = (event) => {
      setValueDirty(true);
      if (extraProps?.onBlur) {
        extraProps?.onBlur && extraProps.onBlur(event);
      }
    };
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setExtraError && setExtraError(false);
    setValue(e);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnterPress) {
      onEnterPress();
    }
  };

  return (
    <TextField
      inputRef={input}
      disabled={disabled}
      placeholder={placeholder}
      value={value ?? ''}
      onChange={onInputChange}
      required={!!validation}
      error={validation ? (valueDirty && !validation?.isValid) || extraError : false}
      helperText={
        (valueDirty && !validation?.isValid && validation?.error) ||
        (extraError && extraErrorMessage) ||
        warningMessage ||
        helperText
      }
      variant="outlined"
      type={type}
      size="small"
      margin="dense"
      sx={{
        width: '100%',
        ...sx,
      }}
      slotProps={{
        input: { notched: false, sx: { borderRadius: 1 }, onKeyDown },
        formHelperText: { sx: { color: warningMessage ? 'warning.dark' : 'inherit' } },
      }}
      {...localExtraProps}
    />
  );
};
