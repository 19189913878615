import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companyDetails: {
    name: '',
    vatNumber: '',
    address: '',
    companyNumber: '',
    locality: '',
    country: '',
    region: '',
  },
  personalDetails: {
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
  },
  billingDetails: {
    bank: '',
    iban: '',
    swift: '',
    country: '',
  },
  billingPlan: {
    subscriptionProfile: undefined,
    currentPlanStartDate: null,
  },
  activeStep: 0,
  parentId: undefined,
  selectedClient: undefined,
};

const registerCompanyClientStepSlice = createSlice({
  name: 'registerCompanyClientStep',
  initialState: {
    data: initialState,
  },
  reducers: {
    setCompanyPersonalDetails: (state, action) => {
      state.data.personalDetails = {
        ...state.data.personalDetails,
        ...action.payload,
      };
    },
    setCompanyDetails: (state, action) => {
      state.data.companyDetails = {
        ...state.data.companyDetails,
        ...action.payload,
      };
    },
    setCompanyParentId: (state, action) => {
      state.data.parentId = action.payload.parentId;
      state.data.selectedClient = action.payload.selectedClient;
    },
    setCompanyBillingDetails: (state, action) => {
      state.data.billingDetails = {
        ...state.data.billingDetails,
        ...action.payload,
      };
    },
    setCompanyBillingPlan: (state, action) => {
      state.data.billingPlan = {
        ...state.data.billingPlan,
        ...action.payload,
      };
    },
    setCompanyRegisterCurrency: (state, action) => {
      state.data.billingPlan = {
        ...state.data.billingPlan,
        subscriptionProfile: {
          ...state.data.billingPlan.subscriptionProfile,
          currency: action.payload.currency,
          vat: action.payload.vat,
        },
      };
    },
    resetRegisterCompanyClientStep: (state) => {
      state.data = initialState;
    },
    increaseRegisterCompanyClientStep: (state) => {
      const stepSize = 1;

      state.data.activeStep += stepSize;
    },
    decreaseRegisterCompanyClientStep: (state) => {
      const stepSize = 1;
      state.data.activeStep -= stepSize;
    },
  },
});

export const {
  setCompanyPersonalDetails,
  setCompanyBillingDetails,
  setCompanyDetails,
  setCompanyBillingPlan,
  resetRegisterCompanyClientStep,
  increaseRegisterCompanyClientStep,
  decreaseRegisterCompanyClientStep,
  setCompanyParentId,
  setCompanyRegisterCurrency,
} = registerCompanyClientStepSlice.actions;

export default registerCompanyClientStepSlice.reducer;
