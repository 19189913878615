import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';
import { hideWindowLoader, showWindowLoader } from '../ui/windowLoaderSlice';
import { fetchVisibleClients } from './clientsSlice';
import { adaptBillingToApi } from '../../../utils/models/billing';

export const fetchClient = createAsyncThunk(
  'client/fetchClient',
  async ({ clientId }, { getState, rejectWithValue }) => {
    try {
      if (!clientId) {
        return getState()?.client?.client;
      }

      const response = await axiosRequest.get(`/clients/${clientId}`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchClientAsParent = createAsyncThunk(
  'client/fetchClientAsParent',
  async ({ clientId, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/clients/${clientId}`);
      onSuccessCallback && onSuccessCallback(response.data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const registerClient = createAsyncThunk(
  'client/registerClient',
  async (
    { user, details, billing, billingPlan, onSuccessCallback, extraData = {}, token = null },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const options = {};

      if (token) {
        options.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      const response = await axiosRequest.post(
        `/clients`,
        {
          user: { ...user, role: 'admin' },
          details,
          billing: { ...adaptBillingToApi(billing) },
          billingPlan,
          ...extraData,
        },
        { ...options }
      );
      onSuccessCallback && onSuccessCallback();
      if (!token) {
        dispatch(fetchVisibleClients({}));
      }
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateClientUser = createAsyncThunk(
  'client/updateClientUser',
  async ({ clientId, user, onSuccessCallback, onErrorCallback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosRequest.put(`/clients/${clientId}/user`, {
        ...user,
      });
      onSuccessCallback && onSuccessCallback(user);
      dispatch(fetchVisibleClients({}));

      return user;
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const updateClientPersonalDetails = createAsyncThunk(
  'client/updateClientPersonalDetails',
  async ({ clientId, user, details, onSuccessCallback, onFailedCallback }, { rejectWithValue, dispatch }) => {
    try {
      await axiosRequest.put(`/clients/${clientId}/user`, { ...user });
      await axiosRequest.put(`/clients/${clientId}/details`, { ...details });

      onSuccessCallback && onSuccessCallback({ user, details });
      dispatch(fetchVisibleClients({}));

      return { user, details };
    } catch (e) {
      onFailedCallback && onFailedCallback();
      return rejectWithValue(e);
    }
  }
);

export const updateClientDetails = createAsyncThunk(
  'client/updateClientDetails',
  async ({ clientId, details, onSuccessCallback, onFailedCallback }, { dispatch, rejectWithValue }) => {
    try {
      await axiosRequest.put(`/clients/${clientId}/details`, { ...details });
      onSuccessCallback && onSuccessCallback(details);
      dispatch(fetchVisibleClients({}));

      return details;
    } catch (e) {
      onFailedCallback && onFailedCallback();
      return rejectWithValue(e);
    }
  }
);

export const updateClientBillingPlan = createAsyncThunk(
  'client/updateClientBillingPlan',
  async ({ clientId, billingPlan, onSuccessCallback, onFailedCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/clients/${clientId}/billing-plan`, { ...billingPlan });
      onSuccessCallback && onSuccessCallback(billingPlan);

      return billingPlan;
    } catch (e) {
      onFailedCallback && onFailedCallback();
      return rejectWithValue(e);
    }
  }
);

export const updateClientBilling = createAsyncThunk(
  'client/updateClientBilling',
  async ({ clientId, billing, onSuccessCallback, onFailedCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/clients/${clientId}/billing`, {
        ...adaptBillingToApi(billing),
      });
      onSuccessCallback && onSuccessCallback(billing);
      return billing;
    } catch (e) {
      onFailedCallback && onFailedCallback();
      return rejectWithValue(e);
    }
  }
);

export const updateClientActivate = createAsyncThunk(
  'client/updateClientActivate',
  async ({ clientId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/clients/${clientId}/activate`);
      dispatch(fetchVisibleClients({}));

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateClientAll = createAsyncThunk(
  'client/updateClientAll',
  async ({ clientId, user, details, billing, onSuccessCallback, token = null }, thunkApi) => {
    try {
      const options = {};

      if (token) {
        options.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      await axiosRequest.put(`/clients/${clientId}/user`, { ...user }, options);
      await axiosRequest.put(`/clients/${clientId}/details`, { ...details }, options);
      await axiosRequest.put(`/clients/${clientId}/billing`, { ...adaptBillingToApi(billing) }, options);
      await axiosRequest.put(`/clients/${clientId}/activate`, {}, options);

      onSuccessCallback && onSuccessCallback();
      if (!token) {
        thunkApi.dispatch(fetchVisibleClients({}));
      }
      return {};
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const inviteClient = createAsyncThunk(
  'client/inviteClient',
  async ({ request, onSuccessCallback }, thunkApi) => {
    try {
      thunkApi.dispatch(showWindowLoader({}));

      await axiosRequest.post(`/clients/invite-${request.type}`, {
        email: request.email,
        sendEmail: true,
        link: `${window.location.origin}/auth/register/${request.type}`,
        billingPlan: request.billingPlan,
        parentId: request.parentId ?? undefined,
      });

      onSuccessCallback && onSuccessCallback();
      thunkApi.dispatch(hideWindowLoader({}));

      return {};
    } catch (e) {
      thunkApi.dispatch(hideWindowLoader({}));

      return thunkApi.rejectWithValue(e);
    }
  }
);

const initialState = {
  client: {
    billing: {},
    user: {},
    details: {},
  },
  parentClient: {},
  status: STATUS_IDLE,
  error: null,
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    resetClient: (state) => {
      state.client = initialState.client;
      state.status = initialState.status;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch
      .addCase(fetchClient.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchClient.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.client = action.payload;
      })
      .addCase(fetchClient.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // register
      .addCase(registerClient.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(registerClient.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.client = action.payload;
      })
      .addCase(registerClient.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // user details
      .addCase(updateClientUser.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateClientUser.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.client.user = { ...state.client.user, ...action.payload };
      })
      .addCase(updateClientUser.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // personal details
      .addCase(updateClientPersonalDetails.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateClientPersonalDetails.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.client.user = { ...state.client.user, ...action.payload.user };
        state.client.details = {
          ...state.client.details,
          ...action.payload.details,
        };
      })
      .addCase(updateClientPersonalDetails.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // client details
      .addCase(updateClientDetails.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateClientDetails.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.client.details = { ...state.client.details, ...action.payload };
      })
      .addCase(updateClientDetails.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update billing details
      .addCase(updateClientBillingPlan.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateClientBillingPlan.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.client.billingPlan = { ...state.client.billingPlan, ...action.payload };
      })
      .addCase(updateClientBillingPlan.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // billing details
      .addCase(updateClientBilling.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateClientBilling.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.client.billing = { ...state.client.billing, ...action.payload };
      })
      .addCase(updateClientBilling.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // activate
      .addCase(updateClientActivate.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateClientActivate.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(updateClientActivate.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update all fields
      .addCase(updateClientAll.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateClientAll.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(updateClientAll.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // invite client
      .addCase(inviteClient.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(inviteClient.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(inviteClient.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      .addCase(fetchClientAsParent.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchClientAsParent.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.parentClient = action.payload;
      })
      .addCase(fetchClientAsParent.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetClient } = clientSlice.actions;
export default clientSlice.reducer;
