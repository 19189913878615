import {
  IBillingEntityForm,
  ISubscriptionPlanForm,
  ICurrency,
  IRegisterPartnerFormPayload,
  ISubscriptionProfile,
} from 'types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IRegisterPartnerFormPayload = {
  details: {
    name: '',
    vatNumber: '',
    address: '',
    companyNumber: '',
    locality: '',
    region: '',
    country: '',
  },
  user: {
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
  },
  billing: {
    companyNumber: '',
    companyId: '',
    companyAddress: '',
    companyName: '',
    bank: '',
    iban: '',
    swift: '',
    country: '',
    bankCountry: '',
    region: '',
    locality: '',
    currency: '',
    nextNumber: '',
    language: '',
  },
  billingPlan: {
    subscriptionProfile: undefined,
    currentPlanStartDate: null,
  } as ISubscriptionPlanForm,
  activeStep: 0,
};

const registerPartnerFormStepSlice = createSlice({
  name: 'registerPartnerFormStep',
  initialState: {
    data: { ...initialState },
  },
  reducers: {
    setPartnerRegisterPersonalDetails: (state, action: PayloadAction<Pick<IRegisterPartnerFormPayload, 'user'>>) => {
      state.data.user = {
        ...state.data.user,
        ...action.payload.user,
      };
    },

    setPartnerRegisterCompanyDetails: (state, action: PayloadAction<Pick<IRegisterPartnerFormPayload, 'details'>>) => {
      state.data.details = {
        ...state.data.details,
        ...action.payload.details,
      };
    },

    setPartnerRegisterBillingDetails: (state, action: PayloadAction<IBillingEntityForm>) => {
      state.data.billing = {
        ...state.data.billing,
        ...action.payload,
      };
    },

    setPartnerRegisterBillingPlan: (state, action: PayloadAction<Pick<IRegisterPartnerFormPayload, 'billingPlan'>>) => {
      state.data.billingPlan = {
        ...state.data.billingPlan,
        ...action.payload.billingPlan,
      };
    },

    setPartnerRegisterCurrency: (state, action: PayloadAction<{ currency: ICurrency; vat: number }>) => {
      state.data.billingPlan = {
        ...state.data.billingPlan,
        subscriptionProfile: {
          ...(state.data.billingPlan.subscriptionProfile ?? ({} as ISubscriptionProfile)),
          currency: action.payload.currency as string,
          vat: action.payload.vat,
        },
      };
    },

    resetPartnerRegisterForm: (state) => {
      state.data = { ...initialState };
    },

    increasePartnerRegisterFormStep: (state) => {
      state.data.activeStep += 1;
    },

    decreasePartnerRegisterFormStep: (state) => {
      state.data.activeStep -= 1;
    },
  },
});

export const {
  setPartnerRegisterPersonalDetails,
  increasePartnerRegisterFormStep,
  resetPartnerRegisterForm,
  setPartnerRegisterBillingDetails,
  setPartnerRegisterBillingPlan,
  setPartnerRegisterCompanyDetails,
  decreasePartnerRegisterFormStep,
  setPartnerRegisterCurrency,
} = registerPartnerFormStepSlice.actions;

export default registerPartnerFormStepSlice.reducer;
