import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosHubRequest } from 'api/axiosHubRequest';
import { ICompanyInfo, IRegion } from 'types';

const cacheDuration = 10 * 60 * 1000; // Cache duration in milliseconds (e.g., 10 minutes)

interface IInitialState {
  loading: boolean;
  error: string | null;
  regionsObject: Record<string, { list: IRegion[]; cacheTimestamp: number }>;
  companyInfo?: {
    data: ICompanyInfo;
  };
}

const initialState: IInitialState = {
  loading: false,
  error: null,
  regionsObject: {},
};

export const fetchRegionsByCountryCode = createAsyncThunk(
  'utils/fetchRegionsByCountryCode',
  async ({ countryCode }: { countryCode: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as { utils: IInitialState };
      const cachedData = state.utils.regionsObject[countryCode];
      const currentTime = Date.now();

      if (cachedData && currentTime - cachedData.cacheTimestamp < cacheDuration) {
        // Return cached data if it is still valid
        return { countryCode, list: cachedData.list, cacheTimestamp: cachedData.cacheTimestamp };
      }

      const response = await axiosHubRequest<IRegion[]>(`/utils/geography/regions/${countryCode}`);
      if (countryCode !== 'RO') {
        return { countryCode, list: [], cacheTimestamp: currentTime };
      }

      return { countryCode, list: response.data, cacheTimestamp: currentTime };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchCompanyInfo = createAsyncThunk(
  'utils/fetchCompanyInfo',
  async (
    {
      identifier,
      countryCode,
      onSuccessCallback,
      onErrorCallback,
    }: {
      identifier: string;
      countryCode: string;
      onSuccessCallback: (data: ICompanyInfo) => void;
      onErrorCallback: () => void;
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosHubRequest<ICompanyInfo>(`/utils/company-info/${identifier}/${countryCode}`);
      if (!response.data?.cui) {
        onErrorCallback?.();
        return undefined;
      }
      onSuccessCallback?.(response.data);
      return { data: response.data };
    } catch (error) {
      onErrorCallback?.();
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRegionsByCountryCode.fulfilled, (state, action) => {
      state.loading = false;
      state.regionsObject[action.payload.countryCode] = {
        list: action.payload.list,
        cacheTimestamp: action.payload.cacheTimestamp,
      };
      state.error = null;
    });

    builder.addCase(fetchCompanyInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.companyInfo = action.payload;
      state.error = null;
    });
  },
});

export default utilsSlice.reducer;
