import {
  DashboardOutlined as DashboardOutlinedIcon,
  CreditCardOutlined as CreditCardOutlinedIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  EvStationOutlined as EvStationOutlinedIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  AccountTreeOutlined as AccountTreeOutlinedIcon,
  HomeWorkOutlined as HomeWorkOutlinedIcon,
  CardMembershipOutlined as CardMembershipOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  PhoneAndroidOutlined as PhoneAndroidOutlinedIcon,
  CorporateFareOutlined as CorporateFareOutlinedIcon,
  ContactSupportOutlined as ContactSupportOutlinedIcon,
  UpgradeOutlined as UpgradeOutlinedIcon,
  HistoryOutlined as HistoryOutlinedIcon,
  ShutterSpeedOutlined as ShutterSpeedOutlinedIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  RoomOutlined as RoomOutlinedIcon,
  DynamicFormOutlined as DynamicFormOutlinedIcon,
  ListAltOutlined as ListAltOutlinedIcon,
  PrivacyTipOutlined as PrivacyTipOutlinedIcon,
} from '@mui/icons-material';

export const loadIcon = (iconName: string, fontSize = '20px', color = '#a5a5a5') => {
  const allIcons = {
    DashboardOutlinedIcon,
    CreditCardOutlinedIcon,
    ErrorOutlineOutlinedIcon,
    EvStationOutlinedIcon,
    AccountCircleOutlinedIcon,
    AccountTreeOutlinedIcon,
    HomeWorkOutlinedIcon,
    CardMembershipOutlinedIcon,
    DescriptionOutlinedIcon,
    PeopleAltOutlinedIcon,
    PhoneAndroidOutlinedIcon,
    CorporateFareOutlinedIcon,
    ContactSupportOutlinedIcon,
    UpgradeOutlinedIcon,
    HistoryOutlinedIcon,
    ShutterSpeedOutlinedIcon,
    BadgeOutlinedIcon,
    RoomOutlinedIcon,
    DynamicFormOutlinedIcon,
    ListAltOutlinedIcon,
    PrivacyTipOutlinedIcon,
  };

  const CustomIcon = allIcons[iconName as keyof typeof allIcons];
  if (!CustomIcon) {
    return null;
  }

  return <CustomIcon sx={{ fontSize, color }} />;
};
