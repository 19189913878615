import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

export const getPhonePrefix = (phone: string): string => {
  if (!phone?.length) {
    return '';
  }
  if (phone.startsWith('07')) {
    return '40';
  }

  try {
    const phoneNumber = parsePhoneNumber(phone);
    return phoneNumber?.countryCallingCode ?? '';
  } catch (e) {
    return '';
  }
};

export const getPhoneSuffix = (phone: string): string => {
  if (!phone?.length) {
    return '';
  }
  if (phone.startsWith('07')) {
    return phone.slice(1);
  }

  try {
    const phoneNumber = parsePhoneNumber(phone);
    return phoneNumber?.nationalNumber ?? '';
  } catch (e) {
    return phone;
  }
};

export const isValidPhone = (phone: string): boolean => {
  if (!phone?.length) {
    return false;
  }

  try {
    return isValidPhoneNumber(phone);
  } catch (e) {
    return false;
  }
};
