import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalDetails: {
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
    address: '',
  },
  billingDetails: {
    bank: '',
    holder: '',
    iban: '',
    swift: '',
    country: '',
  },
  billingPlan: {
    subscriptionProfile: undefined,
    currentPlanStartDate: null,
  },
  activeStep: 0,
  parentId: undefined,
  selectedClient: undefined,
};

const registerIndividualClientStepSlice = createSlice({
  name: 'registerIndividualClientStep',
  initialState: {
    data: initialState,
  },
  reducers: {
    setIndividualPersonalDetails: (state, action) => {
      state.data.personalDetails = {
        ...state.data.personalDetails,
        ...action.payload,
      };
    },
    setIndividualParentId: (state, action) => {
      state.data.parentId = action.payload.parentId;
      state.data.selectedClient = action.payload.selectedClient;
    },
    setIndividualBillingDetails: (state, action) => {
      state.data.billingDetails = {
        ...state.data.billingDetails,
        ...action.payload,
      };
    },
    setIndividualBillingPlan: (state, action) => {
      state.data.billingPlan = {
        ...state.data.billingPlan,
        ...action.payload,
      };
    },
    setIndividualRegisterCurrency: (state, action) => {
      state.data.billingPlan = {
        ...state.data.billingPlan,
        subscriptionProfile: {
          ...state.data.billingPlan.subscriptionProfile,
          currency: action.payload.currency,
          vat: action.payload.vat,
        },
      };
    },
    resetRegisterIndividualClientStep: (state) => {
      state.data = initialState;
    },
    increaseRegisterIndividualClientStep: (state) => {
      const stepSize = 1;

      state.data.activeStep += stepSize;
    },
    decreaseRegisterIndividualClientStep: (state) => {
      const stepSize = 1;
      state.data.activeStep -= stepSize;
    },
  },
});

export const {
  setIndividualPersonalDetails,
  setIndividualParentId,
  setIndividualBillingDetails,
  setIndividualBillingPlan,
  resetRegisterIndividualClientStep,
  increaseRegisterIndividualClientStep,
  decreaseRegisterIndividualClientStep,
  setIndividualRegisterCurrency,
} = registerIndividualClientStepSlice.actions;

export default registerIndividualClientStepSlice.reducer;
