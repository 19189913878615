import { useState } from 'react';
import { Tooltip, IconButton, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTranslation } from 'react-i18next';

export const DateTimeFiltersPicker = ({ column, type }: any) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const dateFrom = column.getFilterValue()?.from ? new Date(column.getFilterValue()?.from) : null;
  const dateTo = column.getFilterValue()?.to ? new Date(column.getFilterValue()?.to) : null;
  const dateType = column?.getFilterValue()?.[type] ? new Date(column?.getFilterValue()?.[type]) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        minDateTime={type === 'to' && dateFrom ? dateFrom : undefined}
        maxDateTime={type === 'from' && dateTo ? dateTo : undefined}
        format="dd MMM yyyy, hh:mm a"
        timeSteps={{ minutes: 1 }}
        onAccept={(newValue) => {
          column?.setFilterValue({
            from: dateFrom,
            to: dateTo,
            [type]: newValue,
          });
        }}
        slotProps={{
          textField: {
            onClick: () => setOpen(true),
            sx: { width: '215px', m: '0 5px 6px 0' },
            placeholder: type === 'from' ? t('global.label.startDate') : t('global.label.endDate'),
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={t('filters.clearFilter')} placement="right" arrow>
                    <span>
                      <IconButton
                        disabled={!dateType}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          const columnCurrentFilter = {
                            from: dateFrom,
                            to: dateTo,
                            [type]: null,
                          };
                          if (columnCurrentFilter?.from === null && columnCurrentFilter?.to === null) {
                            column?.setFilterValue(undefined);
                          } else {
                            column?.setFilterValue(columnCurrentFilter);
                          }
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              ),
            },
          },
          actionBar: { actions: ['today', 'clear', 'cancel', 'accept'] },
        }}
        value={dateType}
      />
    </LocalizationProvider>
  );
};
