import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connectorStatusColor, getConnectorStatusLabel } from 'utils/models/connector';
import { stringCompare } from 'utils/helpers';
import { customConnectorStatus, DefineCellConnectors, DefineCellString } from 'utils/DataGrid';

export const useConnectorColumn = () => {
  const { t } = useTranslation();

  const allUniqueConnectorStatuses = useMemo<string[]>(
    () =>
      [
        ...new Set(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Object.values(connectorStatusColor).map((item) => t(getConnectorStatusLabel(item?.shortName || item.name)))
        ),
      ].sort(stringCompare),
    [connectorStatusColor, t]
  );

  const connectorStatusColumn = useMemo(() => {
    return {
      accessorFn: (row: any) => DefineCellString(row?.connectors ?? [], t),
      id: 'Connectors',
      header: t('global.header.connectors'),
      Cell: ({ row }: any) => <DefineCellConnectors listConnectors={row?.original?.connectors ?? []} />,
      // filterVariant: 'multi-select',
      // filterSelectOptions: allUniqueConnectorStatuses,
      // filterFn: (row: any, _columnIds: any, filterValue: any) =>
      //   customConnectorStatus(row, _columnIds, filterValue, 'Connectors'),
      exportWrapText: () => true,
    };
  }, [t]);

  return {
    connectorStatusColumn,
  };
};
