import { ISubscriptionPlan, ICurrency, ISubscriptionProfile } from 'types';
import { translate } from 'utils/i18n/i18n';

export const getPriceWithVat = (price: number | string | undefined, vat: number) => {
  const safeNumber = Number(price ?? 0);
  return (Math.round(100 * safeNumber + vat * safeNumber) / 100).toFixed(2);
};

export const subscriptionProfileFree = {
  id: null,
  name: translate('global.label.free'),
  currency: ICurrency.RON,
  vat: 0,
} as unknown as ISubscriptionProfile;

export const isFreeSubscriptionProfile = (item?: ISubscriptionProfile) => {
  return item?.name && item?.id === subscriptionProfileFree.id;
};

export const isFreeSubscriptionPlan = (item?: ISubscriptionPlan, isCurrent = true): boolean => {
  if (!item) {
    return false;
  }
  if (isCurrent) {
    return 'currentPlanId' in item && !item.nextPlanId;
  }
  return 'nextPlanId' in item && !item.nextPlanId;
};
