import { forwardRef, useState, useRef, useEffect, PropsWithChildren } from 'react';
import { Box, Button, Tooltip, IconButton, FormControlLabel, Switch, Badge } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_Icons,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ColumnOrderState,
  MRT_VisibilityState,
  MRT_DensityState,
  MRT_SortingState,
  MRT_ColumnFiltersState,
} from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { utils, writeFile } from 'xlsx-js-style';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { LuFilter, LuFilterX } from 'react-icons/lu';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';

import { areObjectsEqual } from 'utils/DataGrid';
import { theme } from 'themes/defaultTheme';
import { fetchUserSettings, updateUserSettings, patchUserSettings } from 'reduxApp/slices/user/userSettingsSlice';
import { useTranslation } from 'react-i18next';
import { getMRTLocale } from '../../../utils/i18n/helper';
import { getLocale } from '../../../utils/i18n/i18n';

export const CustomMRTable = forwardRef(
  (
    {
      columns,
      data,
      tableName,
      enableRowActions,
      styleFirstRow = false,
      onChangeColumnFilters,
      children,
      ...rest
    }: PropsWithChildren<any>,
    forwardedRef: any | null
  ) => {
    const fontAwesomeIcons: Partial<MRT_Icons> = {
      DragHandleIcon: () => <DragIndicatorIcon />,
      FilterListIcon: (props: any) => <LuFilter size={18} {...props} />,
      FilterListOffIcon: () => <LuFilterX size={18} />,
    };

    const { t } = useTranslation();

    const columnsFiltersType = () => {
      const filtersType: any = {};
      for (let i = 0; i < columns.length; i++) {
        const currentKey = columns[i]?.accessorKey || columns[i]?.id;
        let currentValue = 'value';
        if (columns[i]?.filterVariant === 'multi-select') {
          currentValue = 'array';
        }
        filtersType[currentKey] = currentValue;
      }
      return filtersType;
    };

    function isJSON(str: any) {
      if (Number(str) === str * 1) return str;
      try {
        const date = JSON.parse(str);
        return {
          from: date?.from ? new Date(date.from) : null,
          to: date?.to ? new Date(date?.to) : null,
        };
      } catch (e) {
        return str;
      }
    }

    const createFiltersFromQueryParams = (qParams: any) => {
      const columnsFilters = columnsFiltersType();
      const objFilters = [];
      for (let i = 0; i < qParams.length; i++) {
        if (columnsFilters?.[qParams[i][0]] === 'value') {
          objFilters.push({
            id: qParams[i][0],
            value: isJSON(qParams[i][1]),
          });
        }
        if (columnsFilters?.[qParams[i][0]] === 'array') {
          let hasKey = false;
          for (let j = 0; j < objFilters.length; j++) {
            if (objFilters[j]?.id === qParams[i][0]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              objFilters[j].value = [...objFilters[j].value, qParams[i][1]];
              hasKey = true;
              break;
            }
          }
          if (!hasKey) {
            objFilters.push({
              id: qParams[i][0],
              value: [qParams[i][1]],
            });
          }
        }
      }
      return objFilters;
    };

    const [searchParams] = useSearchParams();
    const qParams: any = [...searchParams];

    const [tableInstanceRef] = useState(() => forwardedRef || rest.tableInstanceRef);

    const isFilterQueryParams = !!qParams && qParams.length > 0;

    const getInitialColumnFilters = () => {
      if (isFilterQueryParams) {
        return createFiltersFromQueryParams(qParams);
      }
      if (rest?.initialState?.columnFilters) {
        return [...rest.initialState.columnFilters];
      }
      return [];
    };

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (userId) dispatch(fetchUserSettings({ userId, userLevel }));
    }, []);

    const dispatch = useDispatch();
    const { id: userId, level: userLevel } = useSelector((state: any) => state.user.user);
    const userSettings = useSelector((state: any) => state.userSettings.userSettings);

    // START TABLE PERSISTANCE //
    const [isUserSettings, setIsUserSettings] = useState(false);
    const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([
      ...(enableRowActions ? ['mrt-row-actions'] : []),
      ...columns.map((c: any) => c.accessorKey || c.id),
    ]);

    const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
      rest?.initialState?.columnVisibility ? { ...rest.initialState.columnVisibility } : {}
    );
    const [density, setDensity] = useState<MRT_DensityState>('comfortable');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

    // filters
    const [isAutosaveFilters, setIsAutosaveFilters] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(isFilterQueryParams);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(getInitialColumnFilters());

    const saveUserSettings = (type: string, obj: any) => {
      const bodyObj = {
        [tableName]: { [type]: obj },
      };

      // Verifică dacă setările sunt deja identice
      if (areObjectsEqual(userSettings?.[tableName]?.[type], obj)) {
        return true;
      }

      if (type === 'isAutosaveFilters') {
        if (isAutosaveFilters === true) {
          const bodyObjFilters = {
            [tableName]: {
              isAutosaveFilters,
              showColumnFilters,
              columnFilters,
            },
          };

          if (areObjectsEqual(userSettings?.[tableName]?.isAutosaveFilters, isAutosaveFilters)) {
            return true;
          }

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(patchUserSettings({ userId, bodyObj: bodyObjFilters }));
          return true;
        }

        // if isAutosaveFilters === false
        const bodyObjFilters = {
          [tableName]: {
            isAutosaveFilters,
            showColumnFilters: false,
            columnFilters: [],
          },
        };

        if (areObjectsEqual(userSettings?.[tableName]?.isAutosaveFilters, isAutosaveFilters)) {
          return true;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(patchUserSettings({ userId, bodyObj: bodyObjFilters }));
        return true;
      }

      if (!isAutosaveFilters && (type === 'showColumnFilters' || type === 'columnFilters')) {
        return true;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(patchUserSettings({ userId, bodyObj }));
      return true;
    };

    const resetAllFilters = () => {
      setColumnFilters([]);
    };

    const setColumnFiltersFromJSON = (filtersArray: any) => {
      const objFiltersArray = JSON.parse(JSON.stringify(filtersArray));
      for (let i = 0; i < objFiltersArray.length; i++) {
        if (objFiltersArray[i]?.value?.from) objFiltersArray[i].value.from = new Date(filtersArray[i].value.from);
        if (objFiltersArray[i]?.value?.to) objFiltersArray[i].value.to = new Date(filtersArray[i].value.to);
      }
      setColumnFilters(objFiltersArray);
    };

    const updateTableFromUserSettings = () => {
      if (userSettings?.[tableName]?.columnOrder) {
        setColumnOrder(userSettings[tableName].columnOrder);
      }
      if (userSettings?.[tableName]?.columnVisibility) {
        setColumnVisibility(userSettings[tableName].columnVisibility);
      }
      if (userSettings?.[tableName]?.density) {
        setDensity(userSettings[tableName].density);
      }
      if (userSettings?.[tableName]?.sorting) {
        setSorting(userSettings[tableName].sorting);
      }
      if (userSettings?.[tableName]?.pagination) {
        setPagination(userSettings[tableName].pagination);
      }
      // filters
      if (!isFilterQueryParams && userSettings?.[tableName]?.isAutosaveFilters) {
        if (userSettings?.[tableName]?.isAutosaveFilters) {
          setIsAutosaveFilters(userSettings[tableName].isAutosaveFilters);
        }
        if (userSettings?.[tableName]?.showColumnFilters) {
          setShowColumnFilters(userSettings[tableName].showColumnFilters);
        }
        if (userSettings?.[tableName]?.columnFilters) {
          setColumnFiltersFromJSON(userSettings[tableName].columnFilters);
        }
      }
    };

    useEffect(() => {
      if (userSettings && tableName && !isUserSettings) {
        updateTableFromUserSettings();
        setTimeout(() => setIsUserSettings(true), 0);
      }
    }, [userSettings]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('columnOrder', columnOrder);
      }
    }, [columnOrder]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('columnVisibility', columnVisibility);
      }
    }, [columnVisibility]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('density', density);
      }
    }, [density]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('sorting', sorting);
      }
    }, [sorting]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('pagination', { ...pagination, pageIndex: 0 });
      }
    }, [pagination]);

    // filters
    useEffect(() => {
      if (isUserSettings && tableName && !isFilterQueryParams) {
        saveUserSettings('isAutosaveFilters', isAutosaveFilters);
      }
    }, [isAutosaveFilters]);

    useEffect(() => {
      if (isUserSettings && tableName && !isFilterQueryParams) {
        saveUserSettings('showColumnFilters', showColumnFilters);
      }
    }, [showColumnFilters]);

    useEffect(() => {
      if (isUserSettings && tableName && isFilterQueryParams) {
        onChangeColumnFilters && onChangeColumnFilters(columnFilters, userSettings?.stationLogs?.isDebugMode, true);
      }
      if (isUserSettings && tableName && !isFilterQueryParams) {
        onChangeColumnFilters && onChangeColumnFilters(columnFilters, userSettings?.stationLogs?.isDebugMode);
        saveUserSettings('columnFilters', columnFilters);
      }
    }, [columnFilters, isUserSettings]);
    // END TABLE PERSISTANCE //

    useEffect(() => {
      if (isFilterQueryParams) {
        setColumnFilters(createFiltersFromQueryParams(qParams));
      }
    }, [searchParams]);

    const toggleFilters = (table: any) => {
      setShowColumnFilters((prev) => !prev);
      table.setShowColumnFilters(!showColumnFilters);
    };

    const handleExportRows = () => {
      const sheets = [];
      let wscols: any = [];
      const allVisibleRows = table?.getPrePaginationRowModel()?.rows;

      if (allVisibleRows?.length > 0) {
        for (let i = 0; i < allVisibleRows?.length; i++) {
          const currentCells = allVisibleRows[i]?.getVisibleCells();
          if (i === 0) {
            const headerArr = currentCells
              .filter((el: any) => el.column.columnDef?.notExported !== true && el.column?.id !== 'mrt-row-actions')
              .map((el: any) => ({
                v:
                  (el.column.columnDef?.exportHeader && el.column.columnDef?.exportHeader(el)) ||
                  el.column.columnDef.header,
                t: 's',
                s: { font: { bold: true } },
              }));
            sheets.push(headerArr);
            wscols = currentCells
              .filter((el: any) => el.column.columnDef?.notExported !== true && el.column?.id !== 'mrt-row-actions')
              .map((el: any) => ({ wpx: el.column.columnDef.exportColumnWidth || 150 }));
          }
          const currentRow = currentCells
            .filter((el: any) => el.column.columnDef?.notExported !== true && el.column?.id !== 'mrt-row-actions')
            .map((el: any) => {
              return {
                v: el.column.columnDef?.exportValue ? el.column.columnDef?.exportValue(el) : (el?.getValue() ?? ''),
                t: (el.column.columnDef?.exportColumnFormat && el.column.columnDef?.exportColumnFormat(el)) || 's',
                z:
                  (el.column.columnDef?.exportColumnFormat && el.column.columnDef?.exportColumnFormat(el)) === 'd'
                    ? 'dd MMM yyy, hh:mm AM/PM'
                    : undefined,
                s: {
                  alignment: {
                    vertical: 'top',
                    ...(el.column.columnDef?.exportWrapText && el.column.columnDef?.exportWrapText(el)
                      ? { wrapText: true }
                      : {}),
                  },
                  ...(el.column.columnDef?.exportColor
                    ? { font: { color: { rgb: el.column.columnDef?.exportColor(el) } } }
                    : {}),
                  ...(el.column.columnDef?.exportBgColor
                    ? { fill: { patternType: 'solid', fgColor: { rgb: el.column.columnDef?.exportBgColor(el) } } }
                    : {}),
                },
              };
            });
          sheets.push(currentRow);
        }
      } else {
        return;
      }

      const wb = utils.book_new();

      const ws = utils.aoa_to_sheet(sheets);

      ws['!cols'] = wscols;

      utils.book_append_sheet(wb, ws);
      writeFile(wb, rest?.excelName ? `${rest.excelName}.xlsx` : 'ExcelExport.xlsx');
    };

    const table = useMaterialReactTable({
      columns,
      data,
      layoutMode: 'semantic',

      // table shadow
      muiTablePaperProps: {
        elevation: 0,
      },
      defaultColumn: {
        // grow: true,

        // filters input variant = outlined
        muiFilterTextFieldProps: {
          variant: 'outlined',
        },

        // update all columns styles
        muiTableBodyCellProps: {
          style: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
        },
      },
      muiTableHeadRowProps: {
        sx: {
          // update filters container
          '& .MuiTableCell-head .MuiCollapse-root': {
            mt: 1,
          },

          // font size for all inputs and multi-select for filters
          '& .MuiSelect-select .MuiBox-root, input': {
            fontSize: '13px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },

          // update padding right for all inputs and multi-select for filters
          '& .MuiSelect-select, input': {
            pr: '0px !important',
          },

          // update input default pedding-right:14px
          '& .MuiFormControl-root .MuiOutlinedInput-root': {
            pr: 0,
          },

          // update clear filter (x) default margin-left: 8px
          '& .MuiOutlinedInput-root .MuiInputAdornment-root': {
            ml: 0,

            // update default width: 32px
            width: '26px',
            '& span': {
              width: '26px',
              '& button': {
                width: '26px',
                height: '26px',
              },
            },

            // update clear filter (x) default font-size: 20px
            '.MuiSvgIcon-root': {
              fontSize: '16px',
            },
          },

          // FOR HEADER
          '&.MuiTableRow-head': {
            // hide default sort icon
            '& .MuiBadge-root:has(svg[data-testid="SyncAltIcon"])': {
              display: 'none',
            },

            // hide actions (Move/Column Actions)
            // + update default height: 25px
            '& .Mui-TableHeadCell-Content-Actions': {
              height: '24px',
              // hide Move action
              '& button[aria-label="Move"]': {
                display: 'none',
              },
              // hide Column Actions
              '& button[aria-label="Column Actions"]': {
                visibility: 'hidden',
              },
            },

            // hide column resize
            '& .Mui-TableHeadCell-ResizeHandle-Wrapper': {
              display: 'none',
            },

            ':hover': {
              // show default sort icon
              '& .MuiBadge-root:has(svg[data-testid="SyncAltIcon"])': {
                display: 'inline-flex',
              },

              // show actions (Move/Column Actions)
              '& .Mui-TableHeadCell-Content-Actions': {
                // show Move action
                '& button[aria-label="Move"]': {
                  display: 'inline-flex',
                },
                // show Column Actions
                '& button[aria-label="Column Actions"]': {
                  visibility: 'visible',
                },
              },

              // show column resize
              // + update default color
              '& .Mui-TableHeadCell-ResizeHandle-Wrapper': {
                display: 'table-cell',
                ':hover': {
                  '& .MuiDivider-root': {
                    borderColor: `${theme.palette.primary.main} !important`,
                  },
                },
              },
            },
          },
        },
      },
      enableColumnOrdering: true,
      enableColumnPinning: true,
      enableColumnDragging: true,
      enableColumnResizing: true,
      enableRowVirtualization: true,
      filterFns: {
        myCustomFilterFn: (row: any, id, filterValue) => {
          if (row?.getValue(id))
            return String(row.getValue(id)).toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
          return false;
        },
      },
      globalFilterFn: 'myCustomFilterFn',
      localization: getMRTLocale(getLocale()),
      // eslint-disable-next-line
      renderTopToolbarCustomActions: ({ table }) => (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
          {children || <span />}
          <Box>
            {!isFilterQueryParams && (
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={isAutosaveFilters}
                    onChange={() => setIsAutosaveFilters((prevValue) => !prevValue)}
                  />
                }
                label={<span style={{ fontSize: '13px' }}>{t('table.autosaveFilters')}</span>}
              />
            )}
            <Badge badgeContent={columnFilters.length} color="primary" sx={{ mt: '2px' }}>
              <Button
                onClick={() => toggleFilters(table)}
                size="small"
                sx={{ minWidth: '64px', p: '6px 16px' }}
                variant="outlined"
                startIcon={showColumnFilters ? <LuFilterX /> : <LuFilter />}
              >
                {t('table.filters')}
              </Button>
            </Badge>
            <Tooltip title={t('table.resetFilters')}>
              <IconButton
                sx={{ visibility: `${columnFilters.length > 0 ? 'visible' : 'hidden'}` }}
                onClick={() => resetAllFilters()}
                aria-label="resetFilters"
                size="small"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
            {/* <Button onClick={() => resetAllFilters()} size="small">
                  {t('table.resetFilters')}
                </Button> */}
          </Box>
        </Box>
      ),
      // eslint-disable-next-line
      renderToolbarInternalActions: ({ table }) => (
        <Box>
          {/* eslint-disable */}
          <MRT_ToggleGlobalFilterButton table={table} />
          <Tooltip title="Export excel" placement="bottom" arrow>
            <span>
              <IconButton
                disabled={tableInstanceRef?.current?.getPrePaginationRowModel().rows.length === 0}
                onClick={() => handleExportRows()}
              >
                <SaveAltIcon />
              </IconButton>
            </span>
          </Tooltip>
          {/* <MRT_ToggleFiltersButton table={table} /> */}
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
          {/* eslint-enable */}
        </Box>
      ),
      icons: fontAwesomeIcons,
      muiSearchTextFieldProps: {
        placeholder: 'Search',
        sx: {
          minWidth: '10rem',
          '& input': {
            fontSize: '13px',
          },
        },
      },
      muiTableBodyProps: {
        sx: {
          td: { fontSize: 13 },
          ...(styleFirstRow && {
            'tr.MuiTableRow-root:first-of-type': {
              'td.MuiTableCell-root': {
                backgroundColor: 'rgba(21,101,192,0.15)',
              },
              ':hover': {
                'td.MuiTableCell-root': {
                  backgroundColor: 'rgba(21,101,192,0.20)',
                },
              },
            },
          }),
        },
      },
      enableRowActions: !!enableRowActions,
      ...rest,
      // START TABLE PERSISTANT //
      onColumnOrderChange: setColumnOrder,
      onColumnVisibilityChange: setColumnVisibility,
      onDensityChange: setDensity,
      onSortingChange: setSorting,
      onPaginationChange: setPagination,
      // filters
      onShowColumnFiltersChange: setShowColumnFilters,
      onColumnFiltersChange: setColumnFilters,
      state: {
        columnOrder,
        columnVisibility,
        density,
        sorting,
        pagination,
        // filters
        showColumnFilters,
        columnFilters,
        isLoading: rest?.state?.isLoading,
      },
      enableStickyHeader: true,
      // enableStickyFooter
      // muiTableContainerProps={{
      //   sx: {
      //     display: 'flex',
      //     flexDirection: 'column',
      //     flexGrow: 1,
      //     height: '150px',
      //   },
      // }}
      // END TABLE PERSISTANT //
      tableInstanceRef,
    });

    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          '& .MuiTablePagination-root .MuiBox-root': {
            '& label': { fontSize: '14px', overflow: 'visible' },
            '& div.MuiInputBase-root': {
              mt: '2px',
              pb: '2px',
              '& div': {
                pr: '20px',
                pt: '7px',
              },
            },
          },
        }}
      >
        {isUserSettings && <MaterialReactTable table={table} />}
      </Box>
    );
  }
);
