const measureTextWidth = (text) => {
  if (text === undefined) return 0;
  const font = 'bold 13px Poppins';
  let canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const { width } = context.measureText(text);
  canvas.remove();
  canvas = null;
  return Math.ceil(width);
};

export const maxCells = (headerName, cell) => {
  if (cell === undefined) return measureTextWidth(headerName) + 40;
  if (typeof cell === 'number') {
    return Math.max(cell + 40, measureTextWidth(headerName) + 40);
  }
  return Math.max(measureTextWidth(cell) + 40, measureTextWidth(headerName) + 40);
};

export const customTableIntervalFilter = (row, _columnIds, filterValue, column) => {
  if (!filterValue || (!filterValue?.from && !filterValue?.to)) {
    return true;
  }

  const value = row.getValue(column).toString().split('\n');
  const start = value[0] ? new Date(value[0]) : null;
  const end = value[1] ? new Date(value[1]) : null;

  const filterFrom = filterValue?.from ? filterValue.from : null;
  const filterTo = filterValue?.to ? filterValue.to : null;

  if (start && end) {
    if (filterFrom && filterTo) {
      return start >= filterFrom && end <= filterTo;
    }
    if (filterFrom) {
      return start >= filterFrom;
    }
    if (filterTo) {
      return end <= filterTo;
    }
  }

  if (start && !end) {
    if (filterFrom && filterTo) {
      return start >= filterFrom && start <= filterTo;
    }
    if (filterFrom) {
      return start >= filterFrom;
    }
    if (filterTo) {
      return start <= filterTo;
    }
  }

  return true;
};

export const customRangeSliderFilter = (row, _columnIds, filterValue, columnName, maxSeconds) => {
  if (filterValue && filterValue?.[0] !== null)
    if (maxSeconds && filterValue[1] === maxSeconds) {
      return filterValue[0] <= row.getValue(columnName);
    } else {
      return filterValue[0] <= row.getValue(columnName) && row.getValue(columnName) <= filterValue[1];
    }

  return true;
};

export const customTrueFalse = (row, _columnIds, filterValue, columnName, trueText, falseText) => {
  if (filterValue === trueText) {
    return row.getValue(columnName) === true;
  }
  if (filterValue === falseText) {
    return row.getValue(columnName) === false;
  }
  return 'True False filter error';
};

export const customConnectorStatus = (row, _columnIds, filterValue, columnName) => {
  if (filterValue && filterValue.length === 0) return true;
  if (filterValue && row.getValue(columnName)) {
    let boo = false;
    filterValue.map((el) => {
      if (row.getValue(columnName).includes(el)) boo = true;
      return true;
    });
    return boo;
  }
  return false;
};

export const chargingSessionStatus = (currentStatus, t) => {
  const validStatuses = ['Completed', 'Charging', 'Occupied'];
  return validStatuses.includes(currentStatus ?? '')
    ? t(`chargingHistory.status.${currentStatus}`)
    : t('chargingHistory.status.Unknown');
};

export const addVAT = (cost, VAT = 0) => {
  const costVAT = cost + (cost * VAT) / 100;
  return Math.round(costVAT * 100) / 100;
};

export const areObjectsEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

// API date query filter
export const finalQueryParams = (paramQueryParameters, dateQueryParams) => {
  if (paramQueryParameters && dateQueryParams) {
    return `${paramQueryParameters}&timestamp=${JSON.stringify(dateQueryParams)}`;
  }
  if (!paramQueryParameters && dateQueryParams) {
    return `?timestamp=${JSON.stringify(dateQueryParams)}`;
  }
  if (paramQueryParameters && !dateQueryParams) {
    return paramQueryParameters;
  }
  return null;
};

// export function areObjectsEqual(obj1, obj2) {
//   if (obj1 === obj2) return true;

//   if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
//     return false;
//   }

//   const keys1 = Object.keys(obj1);
//   const keys2 = Object.keys(obj2);

//   if (keys1.length !== keys2.length) return false;
//   // eslint-disable-next-line no-restricted-syntax
//   for (const key of keys1) {
//     if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
//       return false;
//     }
//   }

//   return true;
// }
