import { IApiGenericCallbackPayload, ISubscriptionProfile, ISubscriptionProfileForm, ReduxStatusEnum } from 'types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { subscriptionProfileFree } from 'utils/models/subscriptionPlan';

interface ISubscriptionProfileState {
  list: ISubscriptionProfile[];
  item: ISubscriptionProfile;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: ISubscriptionProfileState = {
  list: [],
  item: {} as ISubscriptionProfile,
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchSubscriptionProfiles = createAsyncThunk(
  'subscriptionProfile/fetchAll',
  async ({ clientId, withFreeItem = true }: { clientId?: string; withFreeItem?: boolean }, { rejectWithValue }) => {
    try {
      let query = '';
      if (clientId?.length) {
        query = `?clientId=${clientId}`;
      }

      const response = await axiosRequest.get<ISubscriptionProfile[]>(`/subscriptionprofiles${query}`);

      if (withFreeItem) {
        return [{ ...subscriptionProfileFree }, ...response.data];
      }
      return [...response.data];
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchSubscriptionProfile = createAsyncThunk(
  'subscriptionProfile/fetchOne',
  async ({ id, onSuccess }: { id: string; onSuccess?: (data: ISubscriptionProfile) => void }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get<ISubscriptionProfile>(`/subscriptionprofiles/${id}`);
      onSuccess && onSuccess(response.data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createSubscriptionProfile = createAsyncThunk(
  'subscriptionProfile/create',
  async (data: IApiGenericCallbackPayload<ISubscriptionProfileForm>, { rejectWithValue }) => {
    const { body, onErrorCallback, onSuccessCallback } = data;
    try {
      const response = await axiosRequest.post<ISubscriptionProfile>('/subscriptionprofiles', body);
      onSuccessCallback && onSuccessCallback();
      return {};
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const updateSubscriptionProfile = createAsyncThunk(
  'subscriptionProfile/update',
  async (data: IApiGenericCallbackPayload<ISubscriptionProfileForm>, { rejectWithValue }) => {
    const { body, id, onErrorCallback, onSuccessCallback } = data;
    try {
      const response = await axiosRequest.put<ISubscriptionProfile>(`/subscriptionprofiles/${id}`, body);
      onSuccessCallback && onSuccessCallback();
      return { ...body };
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const deleteSubscriptionProfile = createAsyncThunk(
  'subscriptionProfile/delete',
  async (data: IApiGenericCallbackPayload<ISubscriptionProfile>, { rejectWithValue }) => {
    const { id, onErrorCallback, onSuccessCallback } = data;
    try {
      await axiosRequest.delete(`/subscriptionprofiles/${id}`);
      onSuccessCallback && onSuccessCallback();
      return id;
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const subscriptionProfileSlice = createSlice({
  name: 'subscriptionProfile',
  initialState,
  reducers: {
    resetSubscriptionProfile: (state) => {
      state.item = {} as ISubscriptionProfile;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionProfiles.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchSubscriptionProfiles.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.list = action.payload;
      })
      .addCase(fetchSubscriptionProfiles.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      })

      .addCase(fetchSubscriptionProfile.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchSubscriptionProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.item = action.payload;
      })
      .addCase(fetchSubscriptionProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      })

      .addCase(createSubscriptionProfile.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(createSubscriptionProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(createSubscriptionProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      })

      .addCase(updateSubscriptionProfile.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updateSubscriptionProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.item = { ...state.item, ...action.payload };
      })
      .addCase(updateSubscriptionProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      })

      .addCase(deleteSubscriptionProfile.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(deleteSubscriptionProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.list = state.list.filter((item) => item.id !== action.payload);
        state.item = {} as ISubscriptionProfile;
      })
      .addCase(deleteSubscriptionProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.payload;
      });
  },
});

export const { resetSubscriptionProfile } = subscriptionProfileSlice.actions;

export default subscriptionProfileSlice.reducer;
