import { CustomPalette } from '../types';

export const customPalette = {
  palette: {
    primary: {
      main: window.location.origin.includes('app.ev-nova.ro') ? '#f02d4a' : '#2a76f4',
      light: window.location.origin.includes('app.ev-nova.ro') ? '#ff304b' : '#2d7eff',
      dark: window.location.origin.includes('app.ev-nova.ro') ? '#d6283f' : '#276bd8',
    },
    customColors: {
      backgroundDrawer: '#160833',
      icon: '#737373',
      toggleButton: '#506176',
      text: '#03060B',
      excelGreen: '#1f6e43',
      statuses: {
        green: '#66bb6a',
        blue: '#29b6f6',
        yellow: '#ffc400',
        red: '#f44336',
        grey: '#9e9e9e',
        purple: '#9c27b0',
        orange: '#ff9100',
        fuxia: '#fa169f',
      },
      widgets: {
        nr1: '#009688',
        nr2: '#ff9800',
        nr3: '#0d8cc6',
        nr4: '#f04134',
        nr5: '#26b56e',
        nr6: '#6a5d7b',
        nr7: '#00afb9',
      },
    },
  },
} as { palette: CustomPalette };
