import React from 'react';
import { MdOutlineEditNote } from 'react-icons/md';
import { RiFileExcel2Line } from 'react-icons/ri';
import { theme } from 'themes/defaultTheme';

export const addTableOptions = (userLevel) => ({
  cards: [
    {
      id: 'cards1',
      title: 'dataGrid.title.cards.importAndActivateSingleCard',
      description: 'dataGrid.description.cards.importAndActivateSingleCard',
      isVisible: [2, 3].includes(userLevel),
      link: '/authorizations/import-and-activate',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
    {
      id: 'cards2',
      title: 'dataGrid.title.cards.importSingleCard',
      description: 'dataGrid.description.cards.importSingleCard',
      isVisible: [2, 3].includes(userLevel),
      link: '/authorizations/import/single',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
    {
      id: 'cards3',
      title: 'dataGrid.title.cards.importAndActivateMultipleCards',
      description: 'dataGrid.description.cards.importAndActivateMultipleCards',
      isVisible: [2, 3].includes(userLevel),
      link: '/authorizations/import-activate',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
    {
      id: 'cards4',
      title: 'dataGrid.title.cards.importMultipleCards',
      description: 'dataGrid.description.cards.importMultipleCards',
      isVisible: [2, 3].includes(userLevel),
      link: '/authorizations/import/multiple',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
    {
      id: 'cards5',
      title: 'dataGrid.title.cards.activateMultipleCards',
      description: 'dataGrid.description.cards.activateMultipleCards',
      isVisible: [1, 2, 3].includes(userLevel),
      link: userLevel === 1 ? '/authorizations/activate' : '/authorizations/activate/multiple',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
  ],
  stations: [
    {
      id: 'stations1',
      title: 'dataGrid.title.stations.importAndActivateSingleStation',
      description: 'dataGrid.description.stations.importAndActivateSingleStation',
      isVisible: [2, 3].includes(userLevel),
      link: '/stations/import-and-activate',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
    {
      id: 'stations2',
      title: 'dataGrid.title.stations.importSingleStation',
      description: 'dataGrid.description.stations.importSingleStation',
      isVisible: [2, 3].includes(userLevel),
      link: '/stations/import/single',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
    {
      id: 'stations3',
      title: 'dataGrid.title.stations.importAndActivateMultipleStations',
      description: 'dataGrid.description.stations.importAndActivateMultipleStations',
      isVisible: [2, 3].includes(userLevel),
      link: '/stations/import-activate',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
    {
      id: 'stations4',
      title: 'dataGrid.title.stations.importMultipleStations',
      description: 'dataGrid.description.stations.importMultipleStations',
      isVisible: [2, 3].includes(userLevel),
      link: '/stations/import/multiple',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
    {
      id: 'stations5',
      title: 'dataGrid.title.stations.activateMultipleStations',
      description: 'dataGrid.description.stations.activateMultipleStations',
      isVisible: [1, 2, 3].includes(userLevel),
      link: userLevel === 1 ? '/stations/activate' : '/stations/activate/multiple',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
  ],
  stationsModels: [
    {
      id: 'stationsModels1',
      title: 'dataGrid.title.stationsModels.addStationModel',
      description: 'dataGrid.description.stationsModels.addStationModel',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/stations/models/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  stationsLocations: [
    {
      id: 'stationsLocations1',
      title: 'dataGrid.title.stationsLocations.addStationLocation',
      description: 'dataGrid.description.stationsLocations.addStationLocation',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/locations/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  stationsChargingProfiles: [
    {
      id: 'stationsChargingProfiles1',
      title: 'dataGrid.title.stationsChargingProfiles.createChargingProfile',
      description: 'dataGrid.description.stationsChargingProfiles.createChargingProfile',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/charging-profiles/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  stationsSmartCharging: [
    {
      id: 'stationsSmartCharging1',
      title: 'dataGrid.title.stationsSmartCharging.createLoadBalancing',
      description: 'dataGrid.description.stationsSmartCharging.createLoadBalancing',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/load-balancing/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  clientsUsers: [
    {
      id: 'clientsUsers1',
      title: 'dataGrid.title.clientsUsers.addClientUser',
      description: 'dataGrid.description.clientsUsers.addClientUser',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/clients/users/add-client-user',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  companyUsers: [
    {
      id: 'companyUsers1',
      title: 'dataGrid.title.companyUsers.addUser',
      description: 'dataGrid.description.companyUsers.addUser',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/company/users/add-users',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  drivers: [
    {
      id: 'drivers1',
      title: 'dataGrid.title.drivers.addDriver',
      description: 'dataGrid.description.drivers.addDriver',

      isVisible: [1, 2, 3].includes(userLevel),
      link: '/drivers/register/manual',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  tariffs: [
    {
      id: 'tariffs1',
      title: 'dataGrid.title.tariffs.addTariff',
      description: 'dataGrid.description.tariffs.addTariff',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/tariffs/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  subscriptionPlans: [
    {
      id: 'subscriptionPlans1',
      title: '',
      description: '',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/subscription-plans/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  clients: [
    {
      id: 'clients1',
      title: 'dataGrid.title.clients.addIndividualClient',
      description: 'dataGrid.description.clients.addIndividualClient',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/clients/register/individual',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
    {
      id: 'clients2',
      title: 'dataGrid.title.clients.addCompanyClient',
      description: 'dataGrid.description.clients.addCompanyClient',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/clients/register/company',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  partners: [
    {
      id: 'partners1',
      title: 'dataGrid.title.partners.addPartner',
      description: 'dataGrid.description.partners.addPartner',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/partners/register/form',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  partnersUsers: [
    {
      id: 'partnersUsers1',
      title: 'dataGrid.title.partnersUsers.addPartnerUser',
      description: 'dataGrid.description.partnersUsers.addPartnerUser',
      isVisible: [1, 2, 3].includes(userLevel),
      link: '/partners/users/add-partner-user',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
});
