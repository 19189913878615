import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import {
  AsyncThunkConfig,
  IPartnerInvitePayload,
  IPartner,
  ReduxStatusEnum,
  IApiGenericCallbackPayload,
  IRegisterPartnerFormPayloadApi,
  IRegisterGuestPartnerPayloadApi,
  IUpdatePartnerDetailsApiPayload,
  IPartnerDetails,
  IUpdatePartnerPersonalDetailsApiPayload,
  IUserPartner,
  IUpdatePartnerBillingApiPayload,
  IUpdatePartnerBillingPlanApiPayload,
  ISubscriptionPlan,
  IBillingEntityForm,
} from 'types';
import { adaptBillingToApi } from 'utils/models/billing';

interface IPartnerState {
  partners: IPartner[];
  partner: IPartner;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: IPartnerState = {
  partners: [],
  partner: {} as IPartner,
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchPartners = createAsyncThunk<IPartner[], any, AsyncThunkConfig>(
  'partner/fetchPartners',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/partners');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const invitePartner = createAsyncThunk<any, IApiGenericCallbackPayload<IPartnerInvitePayload>, AsyncThunkConfig>(
  'partner/invitePartner',
  async (data: IApiGenericCallbackPayload<IPartnerInvitePayload>, { rejectWithValue }) => {
    try {
      await axiosRequest.post('/partners/invite-company', data.body);
      data?.onSuccessCallback && data.onSuccessCallback();
      return {};
    } catch (e) {
      data?.onErrorCallback && data.onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const registerPartnerAction = createAsyncThunk<
  any,
  IApiGenericCallbackPayload<IRegisterPartnerFormPayloadApi>,
  AsyncThunkConfig
>(
  'partner/registerPartner',
  async (data: IApiGenericCallbackPayload<IRegisterPartnerFormPayloadApi>, { rejectWithValue }) => {
    try {
      await axiosRequest.post('/partners', {
        ...data.body,
        billing: {
          ...adaptBillingToApi(data.body?.billing),
        },
      });
      data?.onSuccessCallback && data.onSuccessCallback();
      return {};
    } catch (e) {
      data?.onErrorCallback && data.onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const updatePartnerAllAction = createAsyncThunk(
  'partner/updatePartnerAll',
  async (data: IApiGenericCallbackPayload<IRegisterGuestPartnerPayloadApi>, { rejectWithValue }) => {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${data.body?.token}`,
        },
      };
      const partnerId = data.body?.id;

      await axiosRequest.put(`/partners/${partnerId}/user`, { ...data.body?.user }, options);
      await axiosRequest.put(`/partners/${partnerId}/details`, { ...data.body?.details }, options);
      await axiosRequest.put(`/partners/${partnerId}/billing`, { ...adaptBillingToApi(data.body?.billing) }, options);
      await axiosRequest.put(`/partners/${partnerId}/activate`, {}, options);

      data?.onSuccessCallback && data.onSuccessCallback();

      return {};
    } catch (e) {
      data?.onErrorCallback && data.onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const updatePartnerDetailsAction = createAsyncThunk<
  IPartnerDetails,
  IApiGenericCallbackPayload<IUpdatePartnerDetailsApiPayload>,
  AsyncThunkConfig
>('partner/updatePartnerDetailsAction', async (data, { rejectWithValue }) => {
  try {
    await axiosRequest.put(`/partners/${data?.body?.id ?? ''}/details`, { ...data.body?.details });

    data?.onSuccessCallback && data.onSuccessCallback();

    return { ...(data.body?.details as IPartnerDetails) };
  } catch (e) {
    data?.onErrorCallback && data.onErrorCallback();
    return rejectWithValue(e);
  }
});

export const updatePartnerPersonalDetailsAction = createAsyncThunk<
  IUserPartner,
  IApiGenericCallbackPayload<IUpdatePartnerPersonalDetailsApiPayload>,
  AsyncThunkConfig
>('partner/updatePartnerPersonalDetailsAction', async (data, { rejectWithValue }) => {
  try {
    await axiosRequest.put(`/partners/${data?.body?.id ?? ''}/user`, { ...data.body?.user });

    data?.onSuccessCallback && data.onSuccessCallback();

    return { ...(data.body?.user as IUserPartner) };
  } catch (e) {
    data?.onErrorCallback && data.onErrorCallback();
    return rejectWithValue(e);
  }
});

export const updatePartnerBillingAction = createAsyncThunk<
  IBillingEntityForm,
  IApiGenericCallbackPayload<IUpdatePartnerBillingApiPayload>,
  AsyncThunkConfig
>('partner/updatePartnerBillingAction', async (data, { rejectWithValue }) => {
  try {
    await axiosRequest.put(`/partners/${data?.body?.id ?? ''}/billing`, { ...adaptBillingToApi(data.body?.billing) });

    data?.onSuccessCallback && data.onSuccessCallback();

    return { ...(data.body?.billing as IBillingEntityForm) };
  } catch (e) {
    data?.onErrorCallback && data.onErrorCallback();
    return rejectWithValue(e);
  }
});

export const updatePartnerBillingPlanAction = createAsyncThunk<
  ISubscriptionPlan,
  IApiGenericCallbackPayload<IUpdatePartnerBillingPlanApiPayload>,
  AsyncThunkConfig
>('partner/updatePartnerBillingPlanAction', async (data, { rejectWithValue }) => {
  try {
    await axiosRequest.put(`/partners/${data?.body?.id ?? ''}/billing-plan`, { ...data.body?.billingPlan });

    data?.onSuccessCallback && data.onSuccessCallback();

    return { ...(data.body?.billingPlan as ISubscriptionPlan) };
  } catch (e) {
    data?.onErrorCallback && data.onErrorCallback();
    return rejectWithValue(e);
  }
});

export const getPartnerByIdAction = createAsyncThunk<IPartner, { id: string }, AsyncThunkConfig>(
  'partner/getPartnerByIdAction',
  async (data: { id: string }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/partners/${data.id}`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const partnerSlice = createSlice({
  name: 'partners',
  initialState: { ...initialState },
  reducers: {
    addPartner: (state, action: PayloadAction<IPartner>) => {
      state.partners.push(action.payload);
    },
    resetPartner: (state) => {
      state.partner = {} as IPartner;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch all partners
      .addCase(fetchPartners.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.partners = action.payload;
      })
      .addCase(fetchPartners.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      .addCase(getPartnerByIdAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(getPartnerByIdAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.partner = action.payload;
      })
      .addCase(getPartnerByIdAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // invite partner
      .addCase(invitePartner.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(invitePartner.fulfilled, (state) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(invitePartner.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // update partner all
      .addCase(updatePartnerAllAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updatePartnerAllAction.fulfilled, (state) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(updatePartnerAllAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // update partner details
      .addCase(updatePartnerDetailsAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updatePartnerDetailsAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.partner.details = { ...state.partner.details, ...action.payload };
      })
      .addCase(updatePartnerDetailsAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // update partner personal details
      .addCase(updatePartnerPersonalDetailsAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updatePartnerPersonalDetailsAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.partner.user = { ...state.partner.user, ...action.payload };
      })
      .addCase(updatePartnerPersonalDetailsAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // update Partner Billing
      .addCase(updatePartnerBillingAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updatePartnerBillingAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.partner.billing = { ...state.partner.billing, ...action.payload };
      })
      .addCase(updatePartnerBillingAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // update Partner Billing Plan
      .addCase(updatePartnerBillingPlanAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updatePartnerBillingPlanAction.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.partner.billingPlan = { ...state.partner.billingPlan, ...action.payload };
      })
      .addCase(updatePartnerBillingPlanAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // register partner
      .addCase(registerPartnerAction.pending, (state) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(registerPartnerAction.fulfilled, (state) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(registerPartnerAction.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { addPartner, resetPartner } = partnerSlice.actions;

export default partnerSlice.reducer;
