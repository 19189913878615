import * as React from 'react';
import { ISelect, defaultStyling } from 'types';
import { ItemLabelValue } from 'components/common/ItemLabelValue';
import { BasicSelect } from 'components/common/input/select/BasicSelect';
import { LabelContainer } from 'components/common/input/LabelContainer';

export function CustomSelect({
  showDisabledWithoutOpacity = false,
  placeholder = '',
  value,
  label,
  onChange,
  items,
  disabled = false,
  validation,
  valueDirty,
  setValueDirty,
  showCloseButton = false,
  extraProps = {},
  styling = { ...defaultStyling },
  sx,
  containerSx,
}: ISelect<string>) {
  if (disabled && showDisabledWithoutOpacity) {
    const valueItem = items.find((item) => item.id === value);

    return <ItemLabelValue label={label} value={valueItem?.label ?? value} styling={styling} />;
  }

  return (
    <LabelContainer label={label ?? ''} styling={styling} containerSx={containerSx} required={!!validation}>
      <BasicSelect
        value={value}
        placeholder={placeholder}
        valueDirty={valueDirty}
        setValueDirty={setValueDirty}
        validation={validation}
        disabled={disabled}
        onChange={onChange}
        items={items}
        extraProps={extraProps}
        showCloseButton={showCloseButton}
        sx={sx}
      />
    </LabelContainer>
  );
}
